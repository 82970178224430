import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "v-interdisciplinary" }
const _hoisted_2 = { class: "v-interdisciplinary__section" }
const _hoisted_3 = {
  key: 0,
  class: "v-interdisciplinary__section-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeading = _resolveComponent("TheHeading")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_Card = _resolveComponent("Card")!
  const _component_ButtonSources = _resolveComponent("ButtonSources")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TheHeading, { level: "h1" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.parent.name), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Breadcrumbs),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.sections && _ctx.sections.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
                return (_openBlock(), _createBlock(_component_Card, {
                  key: section.id,
                  title: section.name,
                  description: section.description || ' ',
                  "image-id": section.cover,
                  item: section,
                  actions: _ctx.cardActions,
                  class: "v-interdisciplinary__section-card",
                  disabled: !section.description
                }, null, 8, ["title", "description", "image-id", "item", "actions", "disabled"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_ButtonSources)
  ], 64))
}